
/* OVERRIDES TO TAGETIK STYLES */
#section-accounts {

    .header-nav {
        /* Hide main navigation; but the whole integration should be revised */
        @media (max-width: 768px) {
            display: none !important;
        }
    }

    main {
        a {
            color: $color-link;
            transition: all 0.25s ease-in-out;
            text-decoration-color: transparent;
            text-decoration-thickness: 1px;
            &:hover {
                color: $color-link-alt;
                text-decoration: underline;
                text-decoration-color: $color-link-alt;
                text-decoration-thickness: 1px;
            }
            &.link-icon {
                &:hover {
                    text-decoration: none;
                    span {
                        text-decoration: underline;
                    }
                }
            }
        }

        p {
            margin: 0 0 0.85rem 0;
        }

    }

    #row-content {
        margin-top: 2rem;
    }

    body>footer,
    body>header,
    body>main {
        padding: 0!important;
    }

    .section-title {
        a {
            color: $color-link;
            white-space: nowrap;
            &:hover{
                color: $color-link-alt;
                text-decoration: none;
            }
        }
    }
}

::placeholder {
    color: $color-neutral-2 !important;
    opacity: 1;
    padding: 1rem 0;
  }

.pagination {
    margin: 0.5rem 0;
    background-color: transparent;
    border: none;
    display: flex;
    justify-content: center;
    gap: 0.5rem;
    .page-item {
        padding: 0;
        white-space: nowrap;
        @media (max-width: 768px) {
            &:not(.active, .page-item-next, .page-item-prev) {
                display: none;
            }
        }
        &.active {
            a {
                z-index: unset;
            }
        }
        &-prev {
            margin-right: auto;
            &.disabled {
                visibility: hidden;
            }
        }
        &-next {
            margin-left: auto;
            &.disabled {
                visibility: hidden;
            }
        }
    }
}

.nav-tabs {
    margin: 0;
    font-size: 1.25rem;
    a {
        &:hover {
            text-decoration: none;
        }
    }
}


/* TO REVIEW */

.group_styles
{
    display: flex;
    align-items: center;
    justify-content: space-between;
}


body>main {
    padding-top: 30px !important;
}

ul.pagination > li
{
    display: inline-block;
    padding-left: 5px;
    padding-right: 5px;
}

div.ajax_error {
  margin-top: 50px;
}

div.comments {
    padding-left: 50px;
    padding-right: 50px;
}

/* loader elements */

.loading-wrapper, .loading-wrapper-mini  {
    display: flex;
    align-items: center;
    overflow: hidden;
}

.loading-wrapper { height: 80vh; }

/* minimal style for md editor */
.editor-toolbar button {
    /*color:#401aff;*/
    color: black;
    font-size: 13px;
}


