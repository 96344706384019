.button {
    color: white !important;
    background-color: $color-neutral-2;
    transition: background-color 0.5s ease-in-out;
    display: flex;
    align-items: center;
    place-self: flex-start;
    gap: 0.5em;
    white-space: nowrap;
    margin: 0;
    text-transform: capitalize;
    padding: 0.5rem 1rem;
    font-size: 1rem;
    cursor: pointer;
    svg {
        font-size: 125%;
    }
    &:hover {
        background-color: $color-neutral-2-alt;
        text-decoration: none !important;
    }
    &-primary {
        background-color: $color-wk;
        &:hover {
            background-color: $color-wk-alt;
        }
    }
    &-secondary {
        color: $color-neutral-2 !important;
        background-color: $color-neutral-1;
        &:hover {
            background-color: $color-neutral-2-alt;
            color: white !important;
        }
    }
    &-discreet {
        color: $color-link !important;
        background-color: transparent;
        border: 1px solid $color-neutral-2;
        &:hover {
            background-color: $color-neutral-1;
            color: $color-link-alt !important;
        }
    }
    &-small {
        padding: 0.25rem 0.5rem;
        font-size: 0.85rem;
    }

}