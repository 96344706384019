.section-title {
    display: flex;
    gap: 2rem;
    border-left-color:$color-text-alt;
    margin-bottom: 2rem;
    h2 {
        margin: 0;
        padding: 0;
        a {
            display: flex;
            align-items: center;
            gap: 1rem;
            color: $color-link;
            > svg {
                color: $color-neutral-2;
            }
            &:hover {
                color: $color-link-alt;
            }
        }
    }
}

#content > h2 {
    padding-bottom: 0.5rem;
    margin-bottom: 1rem;
    border-bottom: 5px solid $color-link;
}