#sidenav {
    .vertical-nav {
        font-size: 1.1rem;
        li {
            display: flex;
            gap: 1rem;
            padding: 0rem;
            align-items: center;
            &:hover {
                background-color: $color-neutral-1;
            }
            a {
                display: flex;
                align-items: center;
                flex-grow: 1;
                gap: 0.5rem;
                padding: 0.5rem 0;
                &:hover {
                    text-decoration: none;
                }
                i,
                .svg-inline--fa {
                    color: $color-wk;
                    font-size: 1.5rem;
                    width: 1.5rem;
                }
                &.active {
                    font-weight: bold;
                }

            }
        }
    }
}