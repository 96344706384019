.notifications-container {
	display: flex;
	flex-direction: column;
	gap: 1rem;
	padding-bottom: 2rem;
	.notification-item {
			border: 1px solid $color-neutral-1-alt;
			border-left-color: $color-danger;
			border-radius: 5px;
			font-size: 0.85rem;
			color: $color-text;
			transition: 0.5s all ease-in-out;
			padding: 1rem;
			color: $color-text !important;
		&:hover {
				background-color: $color-neutral-1;
				color: $color-link-alt !important;
				text-decoration: none !important;
		}
		.notification-date {
			color: $color-neutral-2-alt;
		}
		.notification-title {
			font-size: 1rem;
			font-weight: bold;
		}
	}

	.notification-seen {
		border-left-color: $color-neutral-1-alt;
	}
}
