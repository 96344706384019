.change-password form,
.my-profile form {

    padding-bottom: 2rem;

    .fields-wrapper {
      display: flex;
      gap: 2rem;
      .field {
        flex: 1;
      }
    }

    .field {
        input {
            padding: 0.5rem;
            color: $color-text;
            margin: 0;
        }
        select {
            display: block;
            width: 100%;
            background-color: white;
            padding: 0.5rem;
            color: $color-text;
        }

        input[type="checkbox"] {
            transform: scale(1.25);
            margin-right: 0.5rem;
        }
        &-checkbox {
          display: flex;
          align-items: flex-start;
          input[type="checkbox"] {
            margin-top: 0.3rem;
          }
        }

    }

    .buttons {
        text-align: center;
        button {
          all:unset;
          padding: 0.5rem 1rem;
          background-color: $color-wk;
          transition:  all .3s ease;
          color: white;
          cursor: pointer;
          &:hover:not(:disabled) {
            background-color: $color-wk-alt;
          }
          &:disabled {
            opacity: 0.5;
            cursor: initial;
          }
        }
      }

}

.change-password form {
  .field {
    .passwordchecker {
      color: $color-danger;
    }
    .invisible {
      display: none;
    }
    .pass_meter,
    .password-repeat-error {
      opacity: 0;
      transition:  all .3s ease;
    }
    .password-repeat-error {
      color: $color-danger;
    }
    input#form-new-password:valid + .pass_meter {
      opacity: 1;
    }
    input#form-repeat-password:valid + .password-repeat-error {
      opacity: 1;
    }

    .password-input {
      display: flex;
      align-items: center;
      input {
        border-right: none;
        width: 100%;
      }
      &:has(input:valid) + .password-repeat-error,
      &:has(input:valid) + .pass_meter {
        opacity: 1;
      }

      &-toggle {
        border: 1px solid #ddd;
        height: 44px;
        border-left: none;
        cursor: pointer;
        user-select: none;
        text-align: center;
        background: white;
        color: $color-neutral-2;
        width: 2rem;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: space-around;
        &:hover {
          color: $color-wk;
        }
      }
    }


  }
}

.profile-message {
  border: 2px solid $color-neutral-1-alt;
  display: flex;
  gap: 2rem;
  padding: 0.5rem 1rem;
  margin-bottom: 2rem;
  &::before {
    content: "";
    background: url('../images/icon-warning.png') no-repeat center center;
    background-size: contain;
    width: 5rem;
    height: 5rem;
    display: inline-block;
    margin: auto;
  }
  > div {
    flex:1;
    > p {
      margin: 0 0 0.5rem 0;
      line-height: normal;
    }
    > p:first-child {
      font-weight: bold;
      font-size: 1.25rem;
    }
    > p:last-child {
      font-size: 0.85rem;
      color: $color-neutral-2;
    }

  }
}