* {
  margin:0;
  padding:0;
}
*, *::after, *::before {
  box-sizing:border-box;
}
html, body {
  height:100%;
}
body {
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
}
img, fieldset, object {
  border:none;
}
p, h1, h2, h3, h4, h5, h6 {
  overflow-wrap: break-word;
}
input, button, textarea, select {
  font: inherit;
}
sup {
  font-size: 50%;
}

/* Bootstrap resets */
h1, .h1, h2, .h2, h3, .h3 {
  margin-top: 0;
}

button, .button {
  all:unset;
}