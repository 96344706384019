@keyframes load8 {
	0% {
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg);
	}
	100% {
		-webkit-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}
.loader {
	opacity: 0;
	&--visible {
		opacity: 1;
		border-radius: 50%;
		width: 2rem;
		height: 2rem;
		padding: 2rem;
		font-size: 20px;
		position: absolute;
		top: calc(25% - 2rem);
		left: calc(50% - 2rem);
		text-indent: -9999em;
		border-top: 0.25rem solid $color-wk;
		border-right: 0.25rem solid $color-danger;
		border-bottom: 0.25rem solid $color-link;
		border-left: 0.25rem solid $color-text;
		-webkit-transform: translateZ(0);
		-ms-transform: translateZ(0);
		transform: translateZ(0);
		animation: load8 1.1s infinite linear;
		transition: 0.5s ease-in-out opacity;
		&:after {
			border-radius: 50%;
			width: 2rem;
			height: 2rem;
		}
	}
}
