// Tagetik text (works on white and neutral-1)
$color-text: hsl(0, 0%, 20%);
$color-text-alt: hsl(0, 0%, 10%); // same as WK neutral

// Tagetik link (works on white and neutral-1)
$color-link: hsl(207, 38%, 44%);
$color-link-alt: hsl(207, 38%, 34%);

// WK red (works with white)
$color-wk: hsl(81, 71%, 43%);
$color-wk-alt: hsl(81, 71%, 33%);
$color-wl-light: hsl(81, 71%, 95%);

// Tagetik neutral (works with text and qa color)
$color-neutral-1: hsl(0, 0%, 96%);
$color-neutral-1-alt: hsl(0, 0%, 86%);

// WK neutral (works with text-alt)
$color-neutral-2: hsl(0, 0%, 58%);
$color-neutral-2-alt: hsl(0, 0%, 46%);

$color-danger: hsl(356, 79%, 65%);
$color-danger-alt: hsl(356, 79%, 55%);

@import "reset.scss";
@import "spinner.scss";
@import "section_title.scss";
@import "sidenav.scss";
@import "notifications.scss";
@import "buttons.scss";
@import "forms.scss";
@import "table.scss";
@import "modals.scss";
@import "company.scss";
@import "shame.scss";